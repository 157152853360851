import React from "react"

import { graphql, Link } from "gatsby"

import Seo from "../../../components/Seo"
import Layout from "../../../components/Layout"
import SafeAreaWrapper from "../../../components/SafeAreaWrapper"

import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

import Grid from "../../../components/Grid"
import { Button } from "../../../components/Button"

import Hero from "../../../components/Hero"
// import Pagination from "../../../components/Pagination"

import ArticlePreview from "../../../components/ArticlePreview"

const TagPage = ({ data: { page, articles, backgroundImage } }) => {
	let highlightPost, posts
	;[highlightPost, ...posts] = articles.edges

	return (
		<Layout translucent={true}>
			<Seo
				title={page.seo.title}
				description={page.seo.description}
				keywords={page.seo.keywords}
			/>
			<Hero
				title="Insights"
				subtitle="Be always up to date"
				backgroundImage={backgroundImage}
				tint={true}
				small={false}
				translucent={true}
			/>
			<div className="py-spacer-xl">
				<SafeAreaWrapper>
					<Grid className="">
						<div className="col-start-1 col-end-13 lg:col-end-8 xl:col-end-9">
							{highlightPost && (
								<Link
									to={`/blog/${highlightPost.node.slug}`}
									alt={highlightPost.node.title}
									title={highlightPost.node.title}
									className="group">
									{/* aspect-w-3 aspect-h-2 md:aspect-w-16 md:aspect-h-9 */}
									<div className="bg-porcelain w-full relative overflow-hidden rounded-medium group xl:mr-40 h-[225px] lg:h-[275px] xl:h-[450px]">
										<GatsbyImage
											className="w-full h-full duration-500 group-hover:scale-125"
											imgClassName="overflow-hidden rounded-medium"
											image={
												highlightPost.node.teaser_image.imgixImage
													.gatsbyImageData
											}
											objectFit="cover"
											objectPosition="center center"
											alt={highlightPost.node.title}
											title={highlightPost.node.title}
										/>
									</div>

									<div className="col-start-1 col-end-12 md:col-end-8 lg:col-end-7 xl:col-end-8 mt-20 mb-40 group">
										<div className="lg:mr-40">
											<ArticlePreview
												variant="snippet"
												date={highlightPost.node.published_at}
												title={highlightPost.node.title}
												excerpt={highlightPost.node.excerpt}
											/>
										</div>
									</div>
								</Link>
							)}

							{/* md:pr-40 */}
							<div className="grid grid-cols-8">
								<div className="col-span-8 xl:col-span-7">
									<ul className="">
										{posts.map(
											({
												node: {
													title,
													published_at,
													excerpt,
													slug,
													teaser_image,
												},
											}) => (
												<li
													key={`post-${slug}`}
													className="py-30 xl:py-40 xl:border-solid xl:border-iron xl:border-t xl:last:border-b">
													{/* lg:mr-40 */}
													<Link
														to={`/blog/${slug}`}
														alt={title}
														title={title}
														className="flex flex-col xl:flex-row group">
														{/* aspect-w-3 aspect-h-2 md:aspect-w-16 md:aspect-h-9 xl:aspect-w-3 xl:aspect-h-2 */}
														<div className="bg-porcelain w-full h-[225px] lg:h-[275px] xl:w-[300px] xl:h-[200px] flex-none rounded-medium overflow-hidden">
															<GatsbyImage
																className="rounded-medium w-full h-full overflow-hidden duration-500 group-hover:scale-125"
																imgClassName="overflow-hidden rounded-medium "
																objectFit="cover"
																objectPosition="center center"
																image={
																	teaser_image.imgixImage
																		.gatsbyImageData
																}
																alt={title}
																title={title}
															/>
														</div>

														<div className="mt-20 xl:mt-0 xl:ml-30 flex-grow group">
															<ArticlePreview
																className="xl:mb-40"
																variant="snippet"
																date={published_at}
																title={title}
																excerpt={excerpt}
															/>
														</div>
													</Link>
												</li>
											),
										)}
										{/* <div className="my-spacer-md flex flex-row justify-center">
											<Pagination
												urlPrefix="/blog"
												pageContext={pageContext}
											/>
										</div> */}
									</ul>
								</div>
							</div>
						</div>

						<aside className="col-start-1 col-end-13 lg:col-start-8 xl:col-start-9 self-start sticky top-[100px] md:top-[120px] flex flex-col gap-y-60">
							{/* <div className="bg-porcelain flex flex-col items-center rounded-medium">
								<a
									className="overflow-hidden w-full h-full rounded-t-medium"
									href="https://hsft.it/smm-appointment"
									target="_blank"
									rel="noreferrer"
									data-umami-event="book-appointment"
									data-umami-event-event="smm-2024">
									<StaticImage
										src="../images/smm-fernsehturm.png"
										height={236}
										alt="Book an appointment with us at SMM 2024"
										title="We'd love to meet you at SMM 2024"
										className="w-full h-full rounded-t-medium object-cover cursor-pointer duration-500 hover:scale-125 z-0"
									/>
								</a>
								<div className="px-30 pt-30 pb-40 flex flex-col items-center">
									<h3 className="display3 text-center">
										We'd love to meet you at SMM
									</h3>
									<p className="bodyp mt-12 mb-spacer-sm text-center">
										Fill in a form to book an appointment, either to request a
										demo or talk to our digital experts on the stand.
									</p>
									<a
										href="https://hsft.it/smm-appointment"
										target="_blank"
										data-umami-event="book-appointment"
										data-umami-event-event="smm-2024">
										<Button primary={true} label="Book an appointment" />
									</a>
								</div>
							</div> */}

							{/* <div className="bg-porcelain px-30 py-40 flex flex-col items-center rounded-medium">
								<span className="captionBtn text-pictonblue text-center mb-12">
									Newsletter
								</span>
								<h3 className="display3 text-center">
									Subscribe to our newsletter
								</h3>
								<p className="bodyp mt-12 mb-spacer-sm text-center">
									Join hundreds of fleet managers, inspectors and ship owners who
									read our news first.
								</p>

								<a
									href={process.env.GATSBY_NEWSLETTER_SUBSCRIPTION_URL}
									target="_blank"
									rel="noreferrer">
									<Button
										primary
										className="sm:mb-40 md:mb-0 sm:w-full md:w-auto"
										label="Subscribe"
									/>
								</a>
							</div> */}

							<div className="bg-porcelain flex flex-col items-center rounded-medium">
								<div className="w-full aspect-w-16 aspect-h-9 bg-[linear-gradient(247.83deg,_#50ADF1_35.21%,_#246EA7_100%)] rounded-t-medium overflow-hidden relative hover:cursor-pointer">
									<Link
										to="/ai"
										data-umami-event="click-learn-more-about-ai-features">
										<StaticImage
											src="../../../images/ai-manual-search-preview.png"
											alt="Cloud Fleet Manager AI Manual Search Preview"
											className="hover:cursor-pointer duration-500 hover:scale-125 z-0"
											title="Cloud Fleet Manager AI Manual Search Preview"
										/>
									</Link>
								</div>
								<div className="px-30 py-40 flex flex-col items-center gap-y-10 flex-1">
									<h3 className="display3 mb-spacer-sm text-center">
										Discover our newest AI Features to make work easier and
										faster
									</h3>

									<Link to="/ai">
										<Button
											primary={false}
											data-umami-event="click-learn-more-about-ai-features">
											Learn more
										</Button>
									</Link>
								</div>
							</div>

							<div className="bg-porcelain flex flex-col items-center rounded-medium">
								<div className="overflow-hidden w-full aspect-w-16 aspect-h-10 rounded-t-medium">
									<a href="/Cloud-Fleet-Manager-Product-Brochure.pdf" download>
										<StaticImage
											src="../../../images/brochure.jpg"
											alt="Cloud Fleet Manager product portfolio brochure"
											title="Cloud Fleet Manager product portfolio brochure"
											className="rounded-t-medium hover:cursor-pointer duration-500 hover:scale-125 z-0 object-cover object-center"
										/>
									</a>
								</div>
								<div className="px-30 py-40 flex flex-col items-center">
									<span className="captionBtn text-pictonblue text-center mb-12">
										Brochure
									</span>
									<h3 className="display3 text-center">
										Cloud Fleet Manager Product Portfolio Brochure
									</h3>
									<p className="bodyp mt-12 mb-spacer-sm text-center">
										Learn in depth what Cloud Fleet Manager has to offer and how
										the cloud-based approach helps shipping companies.
									</p>
									<a
										href="/Cloud-Fleet-Manager-Product-Brochure.pdf"
										data-umami-event="download-product-brochure"
										download>
										<Button primary={true} label="Download" />
									</a>
								</div>
							</div>
						</aside>
					</Grid>
				</SafeAreaWrapper>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query ($id: String) {
		page: strapiBlogPage {
			seo {
				title
				description
				keywords
			}
		}

		articles: allMongodbBloghanseaticsoftArticles(
			filter: { tags: { elemMatch: { id: { eq: $id } } }, isFuture: { eq: false } }
			sort: { order: DESC, fields: published_at }
		) {
			edges {
				node {
					title
					published_at(formatString: "MMMM DD, YYYY")
					excerpt
					slug
					teaser_image {
						imgixImage {
							gatsbyImageData(layout: CONSTRAINED, width: 840, height: 500)
						}
					}
				}
			}
		}

		backgroundImage: file(relativePath: { eq: "blog.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
			}
		}
	}
`

export default TagPage
